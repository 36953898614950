import { Link } from "gatsby"
import React, { Component } from "react"
import NewsletterForm from "../NewsletterForm/NewsletterForm"
import * as styles from "./Footer.module.css"

export default class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.setColorScheme = this.setColorScheme.bind(this)
  }

  setColorScheme() {
    const colorScheme = this.props.colorScheme
    if (colorScheme === "black") {
      return styles.siteFooterBlack
    }
  }

  render() {
    return (
      <footer className={`${styles.siteFooter} ${this.setColorScheme()}`}>
        <div className={`${styles.container} grid`}>
          <div className={`${styles.siteFooter__list} col-12 col-m4 col-l4`}>
            <h6
              className={`${styles.siteFooter__listTitle} ${styles.siteFooter__listTitleWhite}`}
            >
              Contact
            </h6>
            <p
              className={`${styles.siteFooter__listText} ${styles.siteFooter__listTextWhite}`}
            >
              hello@lostfield.nl
            </p>
            <p
              className={`${styles.siteFooter__listText} ${styles.siteFooter__listTextWhite}`}
            >
              +31 (0)10 30 70 484
            </p>
          </div>
          <div
            className={`${styles.siteFooter__list} ${styles.siteFooter__secondList} col-12 col-m4 col-l4`}
          >
            <h6
              className={`${styles.siteFooter__listTitle} ${styles.siteFooter__listTitleWhite}`}
            >
              Headquarters
            </h6>
            <p
              className={`${styles.siteFooter__listText} ${styles.siteFooter__listTextWhite}`}
            >
              Koninginenbergen 339
            </p>
            <p
              className={`${styles.siteFooter__listText} ${styles.siteFooter__listTextWhite}`}
            >
              Voorburg, The Netherlands
            </p>
          </div>
          <div
            className={`${styles.siteFooter__list} ${styles.siteFooter__thirdList} col-12 col-m8 col-l4`}
          >
            <h6
              className={`${styles.siteFooter__listTitle} ${styles.siteFooter__listTitleWhite}`}
            >
              Subscribe to our newsletter
            </h6>
            <NewsletterForm />
          </div>
          <div
            className={`${styles.siteFooter__bottom} ${styles.siteFooter__bottomLeft} col-12 col-m8 col-l3`}
          ></div>
          <div
            className={`${styles.siteFooter__bottom} ${styles.siteFooter__bottomRight} col-12 col-m8 col-l3`}
          >
            <div className={`${styles.siteFooter__bottomSocial}`}>
              <a
                href="https://www.instagram.com/lostfield"
                className={`${styles.siteFooter__bottomIconLink}`}
              >
                <div
                  className={`${styles.siteFooter__bottomIconBackground} ${styles.siteFooter__bottomIconBackground0}`}
                ></div>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${styles.siteFooter__bottomIcon} ${styles.siteFooter__bottomIconWhite}`}
                >
                  <path
                    d="M24 15.8017C26.67 15.8017 26.9867 15.8117 28.0408 15.86C29.1792 15.9117 30.235 16.14 31.0475 16.9525C31.86 17.765 32.0883 18.8208 32.14 19.9592C32.1883 21.0133 32.1983 21.33 32.1983 24C32.1983 26.67 32.1883 26.9867 32.14 28.0408C32.0883 29.1792 31.86 30.235 31.0475 31.0475C30.235 31.86 29.1792 32.0883 28.0408 32.14C26.9867 32.1883 26.67 32.1983 24 32.1983C21.33 32.1983 21.0133 32.1883 19.9592 32.14C18.8208 32.0883 17.765 31.86 16.9525 31.0475C16.14 30.235 15.9117 29.1792 15.86 28.0408C15.8117 26.9867 15.8017 26.67 15.8017 24C15.8017 21.33 15.8117 21.0133 15.86 19.9592C15.9117 18.8208 16.14 17.765 16.9525 16.9525C17.765 16.14 18.8208 15.9117 19.9592 15.86C21.0133 15.8117 21.33 15.8017 24 15.8017ZM24 14C21.2842 14 20.9433 14.0117 19.8767 14.06C18.2517 14.1342 16.8242 14.5325 15.6783 15.6783C14.5325 16.8242 14.1342 18.2517 14.06 19.8767C14.0117 20.9433 14 21.2842 14 24C14 26.7158 14.0117 27.0567 14.06 28.1233C14.1342 29.7483 14.5325 31.1758 15.6783 32.3217C16.8242 33.4675 18.2517 33.8658 19.8767 33.94C20.9433 33.9883 21.2842 34 24 34C26.7158 34 27.0567 33.9883 28.1233 33.94C29.7483 33.8658 31.1758 33.4675 32.3217 32.3217C33.4675 31.1758 33.8658 29.7483 33.94 28.1233C33.9883 27.0567 34 26.7158 34 24C34 21.2842 33.9883 20.9433 33.94 19.8767C33.8658 18.2517 33.4675 16.8242 32.3217 15.6783C31.1758 14.5325 29.7483 14.1342 28.1233 14.06C27.0567 14.0117 26.7158 14 24 14Z"
                    fill="white"
                  ></path>
                  <path
                    d="M24 18.8647C21.1642 18.8647 18.865 21.1639 18.865 23.9997C18.865 26.8356 21.1642 29.1347 24 29.1347C26.8358 29.1347 29.135 26.8356 29.135 23.9997C29.135 21.1639 26.8358 18.8647 24 18.8647ZM24 27.3331C22.1592 27.3331 20.6667 25.8406 20.6667 23.9997C20.6667 22.1589 22.1592 20.6664 24 20.6664C25.8408 20.6664 27.3333 22.1589 27.3333 23.9997C27.3333 25.8406 25.8408 27.3331 24 27.3331Z"
                    fill="white"
                  ></path>
                  <path
                    d="M29.3384 19.8614C30.0011 19.8614 30.5383 19.3242 30.5383 18.6614C30.5383 17.9987 30.0011 17.4614 29.3384 17.4614C28.6757 17.4614 28.1384 17.9987 28.1384 18.6614C28.1384 19.3242 28.6757 19.8614 29.3384 19.8614Z"
                    fill="white"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.twitter.com/lostfield"
                className={`${styles.siteFooter__bottomIconLink}`}
              >
                <div
                  className={`${styles.siteFooter__bottomIconBackground} ${styles.siteFooter__bottomIconBackground1}`}
                ></div>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${styles.siteFooter__bottomIcon} ${styles.siteFooter__bottomIconWhite}`}
                >
                  <path
                    d="M34 17.9167C33.25 18.25 32.5 18.5 31.6667 18.5833C32.5 18.0833 33.1667 17.25 33.5 16.3333C32.6667 16.8333 31.8333 17.1667 30.9167 17.3333C30.1667 16.5 29.0833 16 27.9167 16C25.6667 16 23.8333 17.8333 23.8333 20.0833C23.8333 20.4167 23.8333 20.75 23.9167 21C20.4167 20.8333 17.4167 19.1667 15.4167 16.6667C15 17.3333 14.8333 18 14.8333 18.75C14.8333 20.1667 15.5833 21.4167 16.6667 22.1667C16 22.1667 15.3333 22 14.8333 21.6667C14.8333 21.6667 14.8333 21.6667 14.8333 21.75C14.8333 23.75 16.25 25.4167 18.0833 25.75C17.75 25.8333 17.4167 25.9167 17 25.9167C16.75 25.9167 16.5 25.9167 16.25 25.8333C16.75 27.5 18.25 28.6667 20.0833 28.6667C18.6667 29.75 16.9167 30.4167 15 30.4167C14.6667 30.4167 14.3333 30.4167 14 30.3333C15.8333 31.5 18 32.1667 20.25 32.1667C27.8333 32.1667 31.9167 25.9167 31.9167 20.5C31.9167 20.3333 31.9167 20.1667 31.9167 20C32.75 19.4167 33.4167 18.6667 34 17.9167Z"
                    fill="white"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.dribble.com/lostfield"
                className={`${styles.siteFooter__bottomIconLink}`}
              >
                <div
                  className={`${styles.siteFooter__bottomIconBackground} ${styles.siteFooter__bottomIconBackground2}`}
                ></div>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${styles.siteFooter__bottomIcon} ${styles.siteFooter__bottomIconWhite}`}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 34C18.5 34 14 29.5 14 24C14 18.5 18.5 14 24 14C29.5 14 34 18.5 34 24C34 29.5 29.5 34 24 34ZM32.4167 25.3333C32.0833 25.25 29.75 24.5 27.0833 25C28.1667 28.0833 28.6667 30.5833 28.75 31.0833C30.6667 29.8333 32.0833 27.75 32.4167 25.3333ZM27.3333 31.9167C27.1667 31.1667 26.75 28.5833 25.5 25.4167C25.5 25.4167 25.5 25.4167 25.4167 25.4167C20.5833 27.0833 18.8333 30.4167 18.75 30.75C20.1667 31.9167 22 32.5833 24 32.5833C25.1667 32.5833 26.3333 32.3333 27.3333 31.9167ZM17.6667 29.75C17.8333 29.4167 20.1667 25.5 24.5833 24.0833C24.6667 24.0833 24.8333 24 24.9167 24C24.6667 23.5 24.5 23 24.25 22.5833C20 23.8333 15.8333 23.8333 15.5 23.8333C15.5 23.9167 15.5 24 15.5 24.0833C15.5 26.1667 16.25 28.25 17.6667 29.75ZM15.6667 22.25C16.0833 22.25 19.5833 22.25 23.5833 21.25C22.1667 18.75 20.6667 16.5833 20.4167 16.3333C18 17.4167 16.1667 19.5833 15.6667 22.25ZM22 15.75C22.25 16.0833 23.75 18.1667 25.1667 20.75C28.1667 19.5833 29.5 17.9167 29.6667 17.6667C28.1667 16.25 26.1667 15.5 24 15.5C23.3333 15.5 22.6667 15.5833 22 15.75ZM30.5833 18.5833C30.4167 18.8333 29 20.6667 25.8333 21.9167C26 22.3333 26.25 22.75 26.4167 23.1667C26.5 23.3333 26.5 23.5 26.5833 23.5833C29.4167 23.25 32.25 23.8333 32.5 23.8333C32.5 21.9167 31.8333 20.0833 30.5833 18.5833Z"
                    fill="white"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/lostfield"
                className={`${styles.siteFooter__bottomIconLink}`}
              >
                <div
                  className={`${styles.siteFooter__bottomIconBackground} ${styles.siteFooter__bottomIconBackground3}`}
                ></div>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${styles.siteFooter__bottomIcon} ${styles.siteFooter__bottomIconWhite}`}
                >
                  <path
                    d="M33.1667 14H14.8333C14.3333 14 14 14.3333 14 14.8333V33.1667C14 33.6667 14.3333 34 14.8333 34H33.1667C33.6667 34 34 33.6667 34 33.1667V14.8333C34 14.3333 33.6667 14 33.1667 14ZM19.9167 31.0833H17V21.5H20V31.0833H19.9167ZM18.4167 20.1667C17.5 20.1667 16.6667 19.4167 16.6667 18.4167C16.6667 17.5 17.4167 16.6667 18.4167 16.6667C19.3333 16.6667 20.1667 17.4167 20.1667 18.4167C20.1667 19.4167 19.4167 20.1667 18.4167 20.1667ZM31.0833 31.0833H28.0833V26.4167C28.0833 25.3333 28.0833 23.9167 26.5833 23.9167C25 23.9167 24.8333 25.0833 24.8333 26.3333V31.0833H21.8333V21.5H24.6667V22.8333C25.0833 22.0833 26 21.3333 27.5 21.3333C30.5 21.3333 31.0833 23.3333 31.0833 25.9167V31.0833Z"
                    fill="white"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.defaultProps = {
  colorScheme: styles.siteFooterBlack,
}
