import React, { Component } from "react"
import Button from "../Button"
import * as styles from "./HomeIntro.module.css"

export default class HomeIntro extends Component {
  render() {
    return (
      <section className={styles.homeIntro}>
        <div className="container grid">
          <div
            className={`${styles.homeIntro__content} col-12 col-s10 col-m10 col-l10`}
          >
            <p className={styles.homeIntro__text}>
              <span className={styles.homeIntro__textWhite}>
                We're problem solvers.
              </span>{" "}
              We listen, observe, create and <br /> validate. Following a
              human-centred approach, we work <br />
              closely with you to get results fast and efficiently.
            </p>
            <div className={`home-intro__button`}>
              <Button to="/about" name="Read more about us" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
