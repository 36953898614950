import { Link } from "gatsby"
import React, { Component } from "react"
import * as styles from "./CaseCard.module.css"

export default class CaseCard extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const orientation =
      this.props.orientation === "top"
        ? "case-card__text"
        : styles.caseCardTextBottom

    return (
      <Link
        to={this.props.to}
        className={`${styles.caseCard} ${styles.caseCardHome}`}
        style={{ backgroundColor: this.props.backgroundColor }}
      >
        <div className={`${orientation}`}>
          <p
            className={styles.caseCard__company}
            style={{ color: this.props.companyColor }}
          >
            {this.props.company}
          </p>
          <h5
            className={`${styles.caseCard__title} ${styles.caseCard__titleHome}`}
            style={{ color: this.props.titleColor }}
          >
            {this.props.title}
          </h5>
          <img
            src={this.props.img}
            alt=""
            className={`${styles.caseCard__image} ${
              this.props.orientation === "top"
                ? styles.caseCard__imageBottom +
                  " " +
                  styles.caseCard__imageBottomHome
                : styles.caseCard__imageTop +
                  " " +
                  styles.caseCard__imageTopHome
            }`}
          />
        </div>
      </Link>
    )
  }
}
