import React, { Component } from "react"
import * as styles from "./HomeBlog.module.css"
import subtract from "./../../../static/subtract.svg"
import SectionTitle from "../SectionTitle"
import author from "./../../../static/jasper.jpg"
import ArrowButton from "../ArrowButton"
import Button from "../Button"

export default class HomeBlog extends Component {
  render() {
    return (
      <section className={styles.homeBlog}>
        <img src={subtract} alt="subtract" className={styles.homeBlog__shape} />
        <div className="container grid">
          <div className={`${styles.homeBlog__title} col-12 col-l12`}>
            <SectionTitle
              headingLevel="h2"
              tag="blog"
              tagAlign="left"
              title="Latest words"
              titleAlign="left"
            />
          </div>
          <p className={`${styles.homeBlog__text} col-12 col-s8 col-m6 col-l5`}>
            Dive into our approach to product development & collaboration
          </p>
          <div className={`${styles.homeBlog__left} col-12 col-l7`}>
            <h5 className={styles.homeBlog__leftTitle}>
              Finding, understanding and solving a design problem in no more
              than 24 ...
            </h5>
            <div className={`${styles.homeBlog__leftBottom}`}>
              <div className={styles.homeBlog__meta}>
                <div className={styles.homeBlog__metaLeft}>
                  <img
                    src={author}
                    alt="blog auteur"
                    className={styles.homeBlog__metaAuthorIcon}
                  />
                </div>
                <div className={styles.homeBlog__metaRight}>
                  <p className={styles.homeBlog__metaAuthorName}>
                    Marc Bijsterveld
                  </p>
                  <p className={styles.homeBlog__metaDate}>March 30, 2020</p>
                </div>
              </div>
              <div className={styles.homeBlog__leftButton}>
                <ArrowButton element="a" />
              </div>
            </div>
          </div>
          <div className={`${styles.homeBlog__right} col-12 col-l5`}>
            <div className={styles.homeBlog__rightContent}>
              <h5 className={styles.homeBlog__rightTitle}>Hungry for more?</h5>
              <p className={styles.homeBlog__rightSubtitle}>
                Read more articles on our blog.
              </p>
            </div>
            <div className={styles.homeBlog__rightButton}>
              <Button to="/blog" name="Visit our blog" style="white" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
