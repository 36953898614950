// extracted by mini-css-extract-plugin
export const homeTestimonials = "HomeTestimonials-module--home-testimonials--3_lPT";
export const homeTestimonials__buttonLeftDesktop = "HomeTestimonials-module--home-testimonials__button-left-desktop--31rXU";
export const homeTestimonials__middle = "HomeTestimonials-module--home-testimonials__middle--1_yjU";
export const homeTestimonials__buttonRightDesktop = "HomeTestimonials-module--home-testimonials__button-right-desktop--FMJvq";
export const homeTestimonials__buttonsMobile = "HomeTestimonials-module--home-testimonials__buttons-mobile--2f4VZ";
export const homeTestimonials__title = "HomeTestimonials-module--home-testimonials__title--1OZq4";
export const homeTestimonials__content = "HomeTestimonials-module--home-testimonials__content--3Y42Z";
export const homeTestimonials__contentText = "HomeTestimonials-module--home-testimonials__content-text--3iV2A";
export const homeTestimonials__meta = "HomeTestimonials-module--home-testimonials__meta--3o4Ah";
export const homeTestimonials__metaAuthor = "HomeTestimonials-module--home-testimonials__meta-author--Cnl6g";
export const homeTestimonials__metaAuthorName = "HomeTestimonials-module--home-testimonials__meta-author-name--11Lim";
export const homeTestimonials__metaAuthorDescription = "HomeTestimonials-module--home-testimonials__meta-author-description--2a7D7";
export const homeTestimonials__metaAuthorImage = "HomeTestimonials-module--home-testimonials__meta-author-image--vrnQh";
export const homeTestimonials__buttonLeftMobile = "HomeTestimonials-module--home-testimonials__button-left-mobile--2in2h";
export const homeTestimonials__slider = "HomeTestimonials-module--home-testimonials__slider--3hF_y";