// extracted by mini-css-extract-plugin
export const homePortfolio = "HomePortfolio-module--home-portfolio--2lvIA";
export const homePortfolio__title = "HomePortfolio-module--home-portfolio__title--1sv8n";
export const homePortfolio__buttonContainer = "HomePortfolio-module--home-portfolio__button-container--m5MCX";
export const homePortfolioMobileContainer = "HomePortfolio-module--home-portfolio-mobile-container--5vcuS";
export const homePortfolio__slider = "HomePortfolio-module--home-portfolio__slider--3VZHH";
export const homePortfolio__buttonMobileCta = "HomePortfolio-module--home-portfolio__button-mobile-cta--3WSzR";
export const homePortfolio__card = "HomePortfolio-module--home-portfolio__card--22zxY";
export const homePortfolio__cardLong = "HomePortfolio-module--home-portfolio__card--long--3TVCO";
export const homePortfolio__cardShort = "HomePortfolio-module--home-portfolio__card--short--xMZeq";
export const homePortfolio__left = "HomePortfolio-module--home-portfolio__left--3-xWY";
export const homePortfolio__right = "HomePortfolio-module--home-portfolio__right--3KZXZ";
export const homePortfolio__button = "HomePortfolio-module--home-portfolio__button--2k9fi";