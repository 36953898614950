import React, { Component } from "react"
import Button from "../Button"
import * as styles from "./PreFooter.module.css"

export default class PreFooter extends Component {
  render() {
    return (
      <div className={`${styles.preFooter} ${styles.preFooterBlack}`}>
        <div className="container grid">
          <h3
            className={`${styles.preFooter__title} ${styles.preFooter__titleWhite} col-12 col-l8`}
          >
            Let’s brew something together!
          </h3>
          <div className={`${styles.preFooter__status} col-12 col-l8`}>
            <span
              className={`${styles.preFooter__subtitle} ${styles.preFooter__subtitleWhite}`}
            >
              <span className={`${styles.preFooter__statusIcon}`}></span>We're
              currently available to be part of your adventure.
            </span>
          </div>
          <div className={`${styles.preFooter__buttons} col-12 col-l4`}>
            <div className={`${styles.preFooter__button}`}>
              <Button style="blue" name="Get in touch" to="/contact" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
