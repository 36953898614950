import React, { Component } from "react"
import SectionTitle from "../SectionTitle"
import * as styles from "./HomeUsps.module.css"

export default class HomeUsps extends Component {
  render() {
    return (
      <section className={styles.homeUsps}>
        <div className="container grid">
          <div
            className={`${styles.homeUsps__title} col-12 col-s8 col-m8 col-l5`}
          >
            <SectionTitle
              tag="benefits"
              title="Why clients choose to work with us"
              tagAlign="left"
              titleAlign="left"
              headingLevel="h3"
            />
          </div>
          <div
            className={`${styles.homeUsps__usp} col-12 col-s11 col-m8 col-l5`}
          >
            <h6 className={styles.homeUsps__uspTitle}>Value for money</h6>
            <p className={styles.homeUsps__uspText}>
              We dare to say your product can be built for any price. Because of
              our agile way of working, we get the most out of your budget and
              do our best to create as much value as possible.
            </p>
          </div>
          <div
            className={`${styles.homeUsps__usp} col-12 col-s11 col-m8 col-l5`}
          >
            <h6 className={styles.homeUsps__uspTitle}>
              Flexible and experienced
            </h6>
            <p className={styles.homeUsps__uspText}>
              We have all of the advantages of a digital agency like experience,
              capacity, and multiple disciplines. We top that all off with
              flexibility, an advantageous rate and dedicated new members for
              your team.
            </p>
          </div>
          <div
            className={`${styles.homeUsps__usp} col-12 col-s11 col-m8 col-l5`}
          >
            <h6 className={styles.homeUsps__uspTitle}>
              Extension of your team
            </h6>
            <p className={styles.homeUsps__uspText}>
              We know we get the best results when we combine forces. The longer
              we're involved, the more we learn and the more value we can bring.
              By optimizing the product, we keep making it better and better,
              making us fall in love with it just as much as users do.
            </p>
          </div>
        </div>
      </section>
    )
  }
}
