import React, { Component } from "react"
import * as styles from "./HomeClients.module.css"
import logoTempoteam from "./../../static/logo-tempo-team.svg"
import Button from "./Button"

export default class HomeClients extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hover: false,
    }

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  onMouseEnter() {
    this.setState(state => ({
      hover: !state.hover,
    }))
  }

  onMouseLeave() {
    this.setState(state => ({
      hover: !state.hover,
    }))
  }

  render() {
    return (
      <section
        className={`${styles.homeClients} `}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div
          className={`container ${styles.homeClientsContainer} ${
            this.state.hover ? styles.homeClientBlur : ""
          }`}
        >
          <div className={styles.homeClients__logoWrapper}>
            <img
              src={logoTempoteam}
              alt="logo tempo team"
              className={styles.homeClients__logo}
            />
          </div>
          <div className={styles.homeClients__logoWrapper}>
            <img
              src={logoTempoteam}
              alt="logo tempo team"
              className={styles.homeClients__logo}
            />
          </div>
          <div className={styles.homeClients__logoWrapper}>
            <img
              src={logoTempoteam}
              alt="logo tempo team"
              className={styles.homeClients__logo}
            />
          </div>
          <div className={styles.homeClients__logoWrapper}>
            <img
              src={logoTempoteam}
              alt="logo tempo team"
              className={styles.homeClients__logo}
            />
          </div>
          <div className={styles.homeClients__logoWrapper}>
            <img
              src={logoTempoteam}
              alt="logo tempo team"
              className={styles.homeClients__logo}
            />
          </div>
        </div>
        <div
          className={`${styles.homeClients__button} ${
            this.state.hover ? styles.homeClientVisible : ""
          }`}
        >
          <Button to="/work#clients" name="View all our clients" />
        </div>
      </section>
    )
  }
}
