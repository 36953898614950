import React, { Component } from "react"
import ArrowButton from "../ArrowButton"
import SectionTitle from "../SectionTitle"
import * as styles from "./HomeTestimonials.module.css"
import Flickity from "react-flickity-component"
import "./../../../node_modules/flickity/dist/flickity.min.css"
import image1 from "./../../../static/takopaddenburg.png"

export default class HomeTestimonials extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount = () => {
    // You can register events in componentDidMount hook
    this.flkty.on("settle", () => {
      console.log(`current index is ${this.flkty.selectedIndex}`)
    })
  }

  flktyNext = () => {
    // You can use Flickity API
    this.flkty.next()
  }

  flktyPrev = () => {
    this.flkty.previous()
  }

  render() {
    const flickityOptions = {
      initialIndex: 0,
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      cellAlign: "left",
      selectedAttraction: 0.003,
      friction: 0.1,
    }

    return (
      <section className={`${styles.homeTestimonials}`}>
        <div className={`container home-testimonials-container grid`}>
          <div
            className={`${styles.homeTestimonials__buttonLeftDesktop}`}
            onClick={this.flktyPrev}
          >
            <ArrowButton
              direction="left"
              alt="arrow pointing to the left"
              element="button"
            />
          </div>
          <div
            className={`${styles.homeTestimonials__middle} col-12 col-m10 col-l10`}
          >
            <div className={`${styles.homeTestimonials__title}`}>
              <SectionTitle
                tag="testimonial"
                tagAlign="center"
                title="Success stories"
                titleAlign="center"
                headingLevel="h2"
              />
            </div>
            <div className={`${styles.homeTestimonials__slider}`}>
              <Flickity
                flickityRef={c => (this.flkty = c)}
                className={"home-testimonials__flickity"} // default ''
                elementType={"div"} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
              >
                <React.Fragment>
                  <div className={`${styles.homeTestimonials__content}`}>
                    <p className={`${styles.homeTestimonials__contentText}`}>
                      “Next to the fact that they are really pleasant people to
                      work with, they add incredible value to our company. Our
                      design flow is way more efficient which is beneficial for
                      both our team internally as our customers.”
                    </p>
                    <div className={`${styles.homeTestimonials__meta}`}>
                      <div className={`${styles.homeTestimonials__metaAuthor}`}>
                        <img
                          src={image1}
                          className={`${styles.homeTestimonials__metaAuthorImage}`}
                        />
                      </div>
                      <h6
                        className={`${styles.homeTestimonials__metaAuthorName}`}
                      >
                        Shanita Kropsla
                      </h6>
                      <p
                        className={`${styles.homeTestimonials__metaAuthorDescription}`}
                      >
                        Co-founder Slalala
                      </p>
                    </div>
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <div className={`${styles.homeTestimonials__content}`}>
                    <p className={`${styles.homeTestimonials__contentText}`}>
                      “Next to the fact that they are really pleasant people to
                      work with, they add incredible value to our company. Our
                      design flow is way more efficient which is beneficial for
                      both our team internally as our customers.”
                    </p>
                    <div className={`${styles.homeTestimonials__meta}`}>
                      <div className={`${styles.homeTestimonials__metaAuthor}`}>
                        <img
                          src={image1}
                          className={`${styles.homeTestimonials__metaAuthorImage}`}
                        />
                      </div>
                      <h6
                        className={`${styles.homeTestimonials__metaAuthorName}`}
                      >
                        Shanita Kropsla
                      </h6>
                      <p
                        className={`${styles.homeTestimonials__metaAuthorDescription}`}
                      >
                        Co-founder Slalala
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              </Flickity>
            </div>
          </div>
          <div
            className={`${styles.homeTestimonials__buttonRightDesktop}`}
            onClick={this.flktyNext}
          >
            <ArrowButton
              direction="right"
              alt="arrow pointing to the right"
              element="button"
            />
          </div>
          <div className={`${styles.homeTestimonials__buttonsMobile} col-12`}>
            <div
              className={`${styles.homeTestimonials__buttonLeftMobile}`}
              onClick={this.flktyPrev}
            >
              <ArrowButton
                direction="left"
                alt="arrow pointing to the left"
                element="button"
              />
            </div>
            <div
              className="home-testimonials__button-right-mobile"
              onClick={this.flktyNext}
            >
              <ArrowButton
                direction="right"
                alt="arrow pointing to the right"
                element="button"
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
