import React, { Component } from "react"
import * as styles from "./HomeVideo.module.css"
import video from "./../../static/test.mp4"
import play from "./../../static/play.svg"
import fullscreen from "./../../static/fullscreen.svg"

export default class HomeVideo extends Component {
  constructor(props) {
    super(props)

    this.state = { overlay: true }

    this.toggleFullscreen = this.toggleFullscreen.bind(this)
    this.playPauseMedia = this.playPauseMedia.bind(this)
  }

  toggleFullscreen = () => {
    let elem = document.querySelector("video")

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch(err => {
        alert(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        )
      })
      setTimeout(() => {
        elem.play()
      }, 100)
    } else {
      document.exitFullscreen()
    }
  }

  playPauseMedia() {
    let elem = document.querySelector("video")

    if (elem.paused) {
      elem.play()
      this.setState(state => ({
        overlay: false,
      }))
    } else {
      elem.pause()
      this.setState(state => ({
        overlay: true,
      }))
    }
  }

  render() {
    return (
      <section className={styles.homeVideo}>
        <div className="container">
          <div className={styles.homeVideo__mp4Wrapper}>
            <video autoPlay muted className={styles.homeVideo__mp4}>
              <source src={video} type="video/mp4" />
            </video>
            <div
              className={`${styles.homeVideo__overlay} ${styles.overlayHidden}`}
              onClick={this.playPauseMedia}
            >
              {this.state.overlay ? (
                <button
                  className={`${styles.homeVideo__button} ${styles.homeVideo__buttonPlay}`}
                >
                  <img
                    src={play}
                    alt="play"
                    className={styles.homeVideo__play}
                  />
                </button>
              ) : (
                ""
              )}
              <button
                className={`${styles.homeVideo__button} ${styles.homeVideo__buttonFullscreen}`}
                onClick={this.toggleFullscreen}
              >
                <img
                  src={fullscreen}
                  alt="fullscreen"
                  className={styles.homeVideo__fullsfreenIcon}
                />
              </button>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
