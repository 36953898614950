// extracted by mini-css-extract-plugin
export const preFooter = "PreFooter-module--pre-footer--1o0ff";
export const preFooterBlack = "PreFooter-module--pre-footer--black--2To4n";
export const preFooter__title = "PreFooter-module--pre-footer__title--4GW-X";
export const preFooter__status = "PreFooter-module--pre-footer__status--6L5cg";
export const preFooter__subtitleWhite = "PreFooter-module--pre-footer__subtitle--white--1tlo4";
export const preFooter__subtitle = "PreFooter-module--pre-footer__subtitle--24cTb";
export const preFooter__statusIcon = "PreFooter-module--pre-footer__status-icon--3mm6x";
export const pulseFooterStatusIcon = "PreFooter-module--pulseFooterStatusIcon--2tLVO";
export const preFooter__buttons = "PreFooter-module--pre-footer__buttons--2ddrn";
export const preFooter__button = "PreFooter-module--pre-footer__button--1qBKI";