import React, { Component } from "react"
import Button from "../Button"
import CaseCard from "../CaseCard/CaseCard"
import HomePortfolioSwiper from "../HomePortfolioSwiper/HomePortfolioSwiper"
import SectionTitle from "../SectionTitle"
import * as styles from "./HomePortfolio.module.css"
import img_1 from "./../../../static/autonl.png"
import img_2 from "./../../../static/tempoteam.png"
import img_3 from "./../../../static/breda.png"
import img_4 from "./../../../static/camptoo.png"

export default class HomePortfolio extends Component {
  render() {
    return (
      <section className={styles.homePortfolio}>
        <div className={styles.homePortfolio__title}>
          <SectionTitle
            tag="Our Work"
            tagAlign="center"
            title="We rather show, then tell"
            titleAlign="center"
            headingLevel="h2"
          />
        </div>
        <div className="container grid">
          <div className={`${styles.homePortfolio__left} col-12 col-m6 col-l6`}>
            <div
              className={`${styles.homePortfolio__card} ${styles.homePortfolio__cardLong}`}
            >
              <CaseCard
                to="/auto"
                backgroundColor="#0099ff"
                orientation="top"
                company="auto.nl"
                companyColor="#ffffff"
                title="Drive your new or used car without hassle"
                titleColor="#ffffff"
                img={img_1}
              />
            </div>
            <div
              className={`${styles.homePortfolio__card} ${styles.homePortfolio__cardLong}`}
            >
              <CaseCard
                to="/tempo-team"
                backgroundColor="#e53012"
                orientation="top"
                company="tempo-team"
                companyColor="#ffffff"
                title="Making jobs work"
                titleColor="#ffffff"
                img={img_2}
              />
            </div>
          </div>
          <div
            className={`${styles.homePortfolio__right} col-12 col-m6 col-l6`}
          >
            <div
              className={`${styles.homePortfolio__card} ${styles.homePortfolio__cardShort}`}
            >
              <CaseCard
                to="/breda"
                backgroundColor="#d91d49"
                orientation="bottom"
                company="citymarketing breda"
                companyColor="#ffffff"
                title="Welcoming visitors to the vibrant city of Breda"
                titleColor="#ffffff"
                img={img_3}
              />
            </div>
            <div
              className={`${styles.homePortfolio__card} ${styles.homePortfolio__cardLong}`}
            >
              <CaseCard
                to="/camptoo"
                backgroundColor="#0056a3"
                orientation="bottom"
                company="camptoo"
                companyColor="#ffffff"
                title="Earning back expenses for motorhome owners"
                titleColor="#ffffff"
                img={img_4}
              />
            </div>
          </div>
        </div>
        <div className={`${styles.homePortfolio__buttonContainer} container`}>
          <div className={`${styles.homePortfolio__button}`}>
            <Button to="/work" name="See all our work" />
          </div>
        </div>
        <div
          className={`${styles.homePortfolioMobileContainer} container grid`}
        >
          <div className={`${styles.homePortfolio__slider} col-12`}>
            <HomePortfolioSwiper />
          </div>
          <div className={`${styles.homePortfolio__buttonMobileCta} col-12`}>
            <Button to="/work" name="See all our work" />
          </div>
        </div>
      </section>
    )
  }
}
