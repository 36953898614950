import React, { Component } from "react"
import * as styles from "./SectionTitle.module.css"

export default class SectionTitle extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.alignTag = this.alignTag.bind(this)

    SectionTitle.defaultProps = {
      tagAlign: "left",
      headingLevel: "h2",
      tag: "tag",
      titleAlign: "left",
      title: "title",
    }
  }

  alignTag(prop) {
    if (prop === "left") {
      return styles.sectionTitleTagLeft
    } else if (prop === "center") {
      return styles.sectionTitleTagCenter
    } else if (prop === "right") {
      return styles.sectionTitleTagRight
    } else {
      return
    }
  }

  alignTitle(prop) {
    if (prop === "left") {
      return styles.sectionTitleLeft
    } else if (prop === "center") {
      return styles.sectionTitleCenter
    } else if (prop === "right") {
      return styles.sectionTitleRight
    } else {
      return
    }
  }

  render() {
    const Title = this.props.headingLevel
    return (
      <div className={styles.sectionTitleWrapper}>
        <p
          className={`${styles.sectionTitleTag} ${this.alignTag(
            this.props.tagAlign
          )}`}
        >
          {this.props.tag}
        </p>
        <Title
          className={`${styles.sectionTitle} ${
            styles.sectionTitleWhite
          } ${this.alignTitle(this.props.titleAlign)}`}
        >
          {this.props.title}
        </Title>
      </div>
    )
  }
}
