// extracted by mini-css-extract-plugin
export const homeSprints = "HomeSprints-module--home-sprints--CGPEf";
export const homeSprints__left = "HomeSprints-module--home-sprints__left--3pbad";
export const homeSprints__right = "HomeSprints-module--home-sprints__right--4ljel";
export const homeSprints__title = "HomeSprints-module--home-sprints__title--sq9Uy";
export const homeSprints__leftSubtitle = "HomeSprints-module--home-sprints__left-subtitle--3urm_";
export const homeSprints__leftText = "HomeSprints-module--home-sprints__left-text--29LA2";
export const homeSprints__rightTop = "HomeSprints-module--home-sprints__right-top--ososn";
export const homeSprints__rightBottom = "HomeSprints-module--home-sprints__right-bottom--9gorE";
export const homeSprints__rightMainImage = "HomeSprints-module--home-sprints__right-main-image--23aT6";
export const homeSprints__rightSecondImage = "HomeSprints-module--home-sprints__right-second-image--2HcPS";
export const homeSprints__rightThirdImage = "HomeSprints-module--home-sprints__right-third-image--7E4IH";