import { graphql, Link } from "gatsby"
import React, { useEffect } from "react"
import Footer from "../components/Footer/Footer"
import Hero from "../components/Hero"
import HomeBlog from "../components/HomeBlog/HomeBlog"
import HomeClients from "../components/HomeClients"
import HomeIntro from "../components/HomeIntro/HomeIntro"
import HomePortfolio from "../components/HomePortfolio/HomePortfolio"
import HomeSprints from "../components/HomeSprints/HomeSprints"
import HomeTestimonials from "../components/HomeTestimonials/HomeTestimonials"
import HomeUsps from "../components/HomeUsps/HomeUsps"
import HomeVideo from "../components/HomeVideo"
import Layout from "../components/Layout"
import PreFooter from "../components/PreFooter/PreFooter"
import * as styles from "../styles/header.module.css"

export default function Home({ data }) {
  const { title, description } = data.site.siteMetadata

  return (
    <Layout>
      <Hero
        title="We create digital products that"
        titleGradient="work"
        text="Lostfield is a digital agency, designing and building the right digital and valuable solutions"
        buttonTo="/services"
        buttonName="View our services"
      />
      <HomeVideo />
      <HomeClients />
      <HomeIntro />
      <HomePortfolio />
      <HomeUsps />
      <HomeBlog />
      <HomeSprints />
      <HomeTestimonials />
      <PreFooter />
      <Footer colorScheme="black" />
    </Layout>
  )
}

export const query = graphql`
  query Siteinfo {
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
