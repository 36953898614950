import React, { Component } from "react"
import SectionTitle from "../SectionTitle"
import * as styles from "./HomeSprints.module.css"
import image1 from "./../../../static/sprints-first-image.png"
import image2 from "./../../../static/sprints-second-image.jpg"
import image3 from "./../../../static/sprints-third-image.jpg"

export default class HomeSprints extends Component {
  render() {
    return (
      <section className={styles.homeSprints}>
        <div className="container grid">
          <div className={`${styles.homeSprints__left} col-12 col-l5`}>
            <div className={`${styles.homeSprints__title}`}>
              <SectionTitle
                headingLevel="h2"
                tag="sprints"
                title="Sprint with us"
              />
            </div>
            <p className={`${styles.homeSprints__leftSubtitle}`}>
              We’ve developed several sprints tailored towards any situation.
              And we can run them for free to give you a taste of the value they
              bring.
            </p>
            <p className={`${styles.homeSprints__leftText}`}>
              Sometimes we run a preparation sprint at the start of a project.
              By setting up a small team, existing of our product team and your
              business experts, we work towards a valuable result within a few
              days! You can’t dream of a better kickstart for the rest of the
              project. And it’s a lot of fun too.
              <br />
              <br />
              We also offer a toned down version of all our sprints for free,
              just to show you the benefits our sprints and team can bring.
              Sounds like an ideal way to get to know each other, don't you
              think?
            </p>
          </div>
          <div className={`${styles.homeSprints__right} col-12 col-l6`}>
            <div className={`${styles.homeSprints__rightTop}`}>
              <img
                src={image1}
                alt="image of phone"
                className={`${styles.homeSprints__rightMainImage} col-10`}
              />
            </div>
            <div className={`${styles.homeSprints__rightBottom}`}>
              <img
                src={image2}
                alt="image of phone"
                className={`${styles.homeSprints__rightSecondImage}`}
              />
              <img
                src={image3}
                alt="image of phone"
                className={`${styles.homeSprints__rightThirdImage}`}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
