// extracted by mini-css-extract-plugin
export const caseCard = "CaseCard-module--case-card--1HNys";
export const caseCardHome = "CaseCard-module--case-card--home--3Gimg";
export const caseCard__company = "CaseCard-module--case-card__company--1Oyu6";
export const caseCard__title = "CaseCard-module--case-card__title--12Tea";
export const caseCard__titleHome = "CaseCard-module--case-card__title--home--2ehiR";
export const caseCard__image = "CaseCard-module--case-card__image--2qM2g";
export const caseCard__imageBottom = "CaseCard-module--case-card__image--bottom--2o6wR";
export const caseCard__imageBottomHome = "CaseCard-module--case-card__image--bottom-home--3actL";
export const caseCardTextBottom = "CaseCard-module--case-card--text-bottom--KuGXC";
export const caseCard__imageTop = "CaseCard-module--case-card__image--top--3EpGi";
export const caseCard__imageTopHome = "CaseCard-module--case-card__image--top-home--3xzP7";