import React, { Component } from "react"
import * as styles from "./../HomePortfolio/HomePortfolio.module.css"
import Flickity from "react-flickity-component"
import "./../../../node_modules/flickity/dist/flickity.min.css"
import { Link } from "gatsby"
import CaseCard from "../CaseCard/CaseCard"
import img_1 from "./../../../static/autonl.png"
import img_2 from "./../../../static/tempoteam.png"
import img_3 from "./../../../static/breda.png"
import img_4 from "./../../../static/camptoo.png"

// Import Swiper styles
// import "swiper/swiper.scss"

export default class HomePortfolioSwiper extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const flickityOptions = {
      initialIndex: 0,
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      cellAlign: "left",
      selectedAttraction: 0.003,
      friction: 0.1,
    }

    return (
      <Flickity
        className={"home-portfolio__flickity"} // default ''
        elementType={"div"} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        <div
          className={`${styles.homePortfolio__card} ${styles.homePortfolio__cardLong}`}
        >
          <CaseCard
            to="/auto"
            backgroundColor="#0099ff"
            orientation="top"
            company="auto.nl"
            companyColor="#ffffff"
            title="Drive your new or used car without hassle"
            titleColor="#ffffff"
            img={img_1}
          />
        </div>
        <div
          className={`${styles.homePortfolio__card} ${styles.homePortfolio__cardLong}`}
        >
          <CaseCard
            to="/tempo-team"
            backgroundColor="#e53012"
            orientation="top"
            company="tempo-team"
            companyColor="#ffffff"
            title="Making jobs work"
            titleColor="#ffffff"
            img={img_2}
          />
        </div>
        <div
          className={`${styles.homePortfolio__card} ${styles.homePortfolio__cardLong}`}
        >
          <CaseCard
            to="/breda"
            backgroundColor="#d91d49"
            orientation="bottom"
            company="citymarketing breda"
            companyColor="#ffffff"
            title="Welcoming visitors to the vibrant city of Breda"
            titleColor="#ffffff"
            img={img_3}
          />
        </div>
        <div
          className={`${styles.homePortfolio__card} ${styles.homePortfolio__cardLong}`}
        >
          <CaseCard
            to="/camptoo"
            backgroundColor="#0056a3"
            orientation="bottom"
            company="camptoo"
            companyColor="#ffffff"
            title="Earning back expenses for motorhome owners"
            titleColor="#ffffff"
            img={img_4}
          />
        </div>
      </Flickity>
    )
  }
}
