import { Link } from "gatsby"
import React, { Component } from "react"
import * as styles from "./ArrowButton.module.css"
import arrowRightWhite from "./../../static/arrow-right--white.svg"
import arrowLeftWhite from "./../../static/arrow-left--white.svg"

export default class ArrowButton extends Component {
  constructor(props) {
    super(props)

    this.state = { color: this.props.color, size: this.props.size }

    this.arrowDirection = this.arrowDirection.bind(this)
    this.renderArrowButton = this.renderArrowButton.bind(this)
    this.arrowSize = this.arrowSize.bind(this)
    this.arrowColor = this.arrowColor.bind(this)
    this.svgSize = this.svgSize.bind(this)
  }

  arrowDirection() {
    if (this.props.direction === "left") {
      return arrowLeftWhite
    } else {
      return arrowRightWhite
    }
  }

  arrowSize() {
    if (this.props.size === "large") {
      return styles.arrowButtonLarge
    } else if (this.props.size === "small") {
      return styles.arrowButtonSmall
    }
  }

  svgSize() {
    if (this.props.size === "large") {
      return styles.arrowButton__svg
    } else if (this.props.size === "small") {
      return styles.arrowButton__svgSmall
    }
  }

  arrowColor() {
    if (this.props.color === "grey") {
      return styles.arrowButtonGrey
    } else if (this.props.color === "blue") {
      return styles.arrowButtonBlue
    }
  }

  renderArrowButton() {
    const Element = this.props.element
    console.log(this.props.element)
    if (this.props.element === "link") {
      return (
        <Link
          href="https://reverbmedia.nl"
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.props.onClick}
          className={`${
            styles.arrowButton
          } ${this.arrowSize()} ${this.arrowColor()}`}
        >
          <div className={styles.arrowButton__background}></div>
          <img
            src={this.arrowDirection()}
            alt={this.props.alt}
            className={this.svgSize()}
          />
        </Link>
      )
    } else if (this.props.element === "a") {
      return (
        <a
          href="https://reverbmedia.nl"
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.props.onClick}
          className={`${
            styles.arrowButton
          } ${this.arrowSize()} ${this.arrowColor()}`}
        >
          <div className={styles.arrowButton__background}></div>
          <img
            src={this.arrowDirection()}
            alt={this.props.alt}
            className={this.svgSize()}
          />
        </a>
      )
    } else {
      return (
        <Element
          onClick={this.props.onClick}
          className={`${
            styles.arrowButton
          } ${this.arrowSize()} ${this.arrowColor()}`}
        >
          <div className={styles.arrowButton__background}></div>
          <img
            src={this.arrowDirection()}
            alt={this.props.alt}
            className={this.svgSize()}
          />
        </Element>
      )
    }
  }

  render() {
    return this.renderArrowButton()
  }
}

ArrowButton.defaultProps = {
  direction: "right",
  alt: "arrow pointing to the right",
  element: "a",
  size: "large",
  color: "grey",
}
