// extracted by mini-css-extract-plugin
export const siteFooter = "Footer-module--site-footer--3UKyc";
export const siteFooterBlack = "Footer-module--site-footer--black--2IKbr";
export const container = "Footer-module--container--1RDYA";
export const siteFooter__list = "Footer-module--site-footer__list--23pJP";
export const siteFooter__secondList = "Footer-module--site-footer__second-list--ugFBR";
export const siteFooter__thirdList = "Footer-module--site-footer__third-list--1JZXT";
export const siteFooter__bottom = "Footer-module--site-footer__bottom--lFZyC";
export const siteFooter__bottomLeft = "Footer-module--site-footer__bottom-left--3gNik";
export const siteFooter__bottomRight = "Footer-module--site-footer__bottom-right--uW13C";
export const siteFooter__listTitle = "Footer-module--site-footer__list-title--1ZqQQ";
export const siteFooter__listTitleWhite = "Footer-module--site-footer__list-title--white--IrdLM";
export const siteFooter__listText = "Footer-module--site-footer__list-text--24f9_";
export const siteFooter__listTextWhite = "Footer-module--site-footer__list-text--white--2MbAI";
export const siteFooter__bottomSocial = "Footer-module--site-footer__bottom-social--gnlIQ";
export const siteFooter__bottomIconLink = "Footer-module--site-footer__bottom-icon-link--3OacZ";
export const siteFooter__bottomIconBackground = "Footer-module--site-footer__bottom-icon-background--cu9yl";
export const siteFooter__bottomIconBackground0 = "Footer-module--site-footer__bottom-icon-background--0--176T6";
export const siteFooter__bottomIconBackground1 = "Footer-module--site-footer__bottom-icon-background--1--l1YyI";
export const siteFooter__bottomIconBackground2 = "Footer-module--site-footer__bottom-icon-background--2--3w2QZ";
export const siteFooter__bottomIconBackground3 = "Footer-module--site-footer__bottom-icon-background--3--1yzKF";
export const siteFooter__bottomIcon = "Footer-module--site-footer__bottom-icon--31U1j";
export const siteFooter__bottomIconWhite = "Footer-module--site-footer__bottom-icon--white--3H2ML";