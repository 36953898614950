// extracted by mini-css-extract-plugin
export const homeBlog = "HomeBlog-module--home-blog--31HMp";
export const homeBlog__shape = "HomeBlog-module--home-blog__shape--1NWjS";
export const homeBlog__title = "HomeBlog-module--home-blog__title--3jgWm";
export const homeBlog__leftTitle = "HomeBlog-module--home-blog__left-title--BLB9y";
export const homeBlog__text = "HomeBlog-module--home-blog__text--2EIvq";
export const homeBlog__left = "HomeBlog-module--home-blog__left--VnyIv";
export const homeBlog__right = "HomeBlog-module--home-blog__right--1ljHS";
export const homeBlog__metaAuthorName = "HomeBlog-module--home-blog__meta-author-name--zF8YY";
export const homeBlog__leftBottom = "HomeBlog-module--home-blog__left-bottom--10M-f";
export const homeBlog__metaLeft = "HomeBlog-module--home-blog__meta-left--1FtCW";
export const homeBlog__meta = "HomeBlog-module--home-blog__meta--3X0h7";
export const homeBlog__leftButton = "HomeBlog-module--home-blog__left-button--P55gO";
export const homeBlog__metaRight = "HomeBlog-module--home-blog__meta-right--1M_U0";
export const homeBlog__metaAuthorIcon = "HomeBlog-module--home-blog__meta-author-icon--1tH1j";
export const homeBlog__metaDate = "HomeBlog-module--home-blog__meta-date--1b-cV";
export const homeBlog__rightContent = "HomeBlog-module--home-blog__right-content--dwg7H";
export const homeBlog__rightButton = "HomeBlog-module--home-blog__right-button--Li2Iq";
export const homeBlog__rightTitle = "HomeBlog-module--home-blog__right-title--1lOyY";
export const homeBlog__rightSubtitle = "HomeBlog-module--home-blog__right-subtitle--36uSl";