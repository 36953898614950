import React, { Component } from "react"
import ArrowButton from "./../ArrowButton.js"
import * as styles from "./NewsletterForm.module.css"

export default class NewsletterForm extends Component {
  formSubmit(e) {
    e.preventDefault()
  }

  render() {
    return (
      <form
        className={`${styles.siteFooter__form} ${styles.siteFooter__formBlack}`}
      >
        <input
          className={`${styles.siteFooter__input} ${styles.siteFooter__inputBlack}`}
          type="email"
          name="email"
          placeholder="E-mail address"
        />
        <ArrowButton
          size="small"
          color="blue"
          element="button"
          onClick={this.formSubmit}
        />
      </form>
    )
  }
}
